<template>
<div>
  <Menu/>
  <div class="volna">
    <transition-group name="fade" tag="div">
      <div v-for="i in [currentIndex]" :key="i" class="obrazek">
        <a v-if="currentIndex > 0" class="prev" @click="prev" href="#">&#10094;</a>
        <v-img contain :src="`https://evzenhanzalik.cz/${images[currentIndex]}`" :max-height="`${window.height}`- 250" :max-width="700"></v-img>
        <a v-if="currentIndex < images.length - 1" class="next" @click="next" href="#">&#10095;</a>
      </div>
    </transition-group>
        <p class="nazev">{{ currentImgName }} ({{ currentIndex + 1 }} / {{ images.length }})
        <span><v-icon v-if="loggedIn" @click="smazObr(`${images[currentIndex]}`)">mdi-delete</v-icon></span></p>
  </div>
</div>
</template>

<script>
import axios from "axios";
import Menu from '../components/Menu';

export default {
  name: "volna",
  components:{
    Menu
  },
  data() {
    return {
      images: [],
      timer: null,
      currentIndex: 0,
      loggedIn: '',
      window: {
            width: 0,
            height: 0
        }
    };
  },

  created: function () {
    this.getFileNames();
    this.loggedIn = this.$store.getters.getLoggedIn;
    window.addEventListener("keyup", e => this.previousPicKey(e));
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
        window.removeEventListener('resize', this.handleResize);
  },

  computed: {
    currentImg: function () {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
    currentImgName: function () {
      if (this.images == 0){ 
        return '';
      } else {
        var str = this.images[this.currentIndex];
        var strPoz = str.indexOf("/");
        return str.slice(strPoz + 1, -4);
      }
    },
  },

  methods: {
    handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
    },
    getFileNames() {
      axios
        .post(
          "https://evzenhanzalik.cz/listOfFileName.php",
          {folder: "volna"}
        )
        .then((response) => {
          this.images = response.data;
          console.log("Images " + this.images);
        });
    },
    next: function () {
      if (this.currentIndex < this.images.length - 1)
      this.currentIndex += 1;
    },
    prev: function () {
      if (this.currentIndex > 0){
      this.currentIndex -= 1;
      }
    },
    previousPicKey(e) {
      if (e.keyCode == 37) {
        this.prev();
      } else if (e.keyCode == 39) {
        this.next();
      }
    },
    smazObr(link){
      if (confirm("Obrázek bude smazán.")) {
      axios
        .post(
          "https://evzenhanzalik.cz/deleteObr.php",
          {myLink: link}
        )
        .then(() => {
          this.getFileNames();          
        });
      }
    }
  },

};
</script>

<style scoped>
.volna{
   width: 800px;
   padding: 30px 0 30px 0;
   border: 1px solid #EEEEEE;
}
.obrazek{
  display: flex;
  /* height: 500px; */
  justify-content: center;
  align-items: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}
img {
  /* height: 500px; */
  max-width: 700px;
  
}
.prev,
.next {
  cursor: pointer;
  width: 50px;
  height: 50px;
  color:black;
  font-size: 25px;
  border-radius: 50%;
  text-decoration: none;
  user-select: none;
}
.prev {
  padding: 6px 15px;
}
.next {
  padding: 6px 18px;
}
.prev:hover,
.next:hover {
  background-color: #EEEEEE;
  color: white;
}
.nazev{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0 5px 0;;
}
</style>